.row {
  $greeting-message-color: var(
    --colors-components-greeting-text,
    var(--colors-text-body-0)
  );

  flex-direction: row;
  display: flex;

  svg {
    height: 24px;
    width: 24px;
    margin-right: 8px;
    fill: $greeting-message-color;
  }

  p {
    color: $greeting-message-color;
  }
}

.container {
  margin-bottom: 40px;
}
